import React, { useContext } from 'react';
import { PrismicContext } from 'gatsby-plugin-prismic-previews';
import markerSDK from '@marker.io/browser';

export default function MarkerIoLoader ({ children }) {
  const [ context ] = useContext(PrismicContext);

  if (context.previewState === 'ACTIVE') {
    markerSDK.loadWidget({
      destination: '611fc3ba96641a2868690346'
    }).then((widget) => {
      window.widget = widget;
    });
  }

  return (
    <>{children}</>
  );
}
