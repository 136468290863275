module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"gallium-studios","promptForAccessToken":true,"apiEndpoint":"https://gallium-studios.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon-32x32.png","icons":[{"src":"src/images/android-chrome-192x192.png","sizes":"192x192","type":"images/png"},{"src":"src/images/android-chrome-512x512.png","sizes":"512x512","type":"images/png"}],"theme_color":"#e59c14","background_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4c70969544cc62de0f45f3b6b2dd2468"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
