/* eslint-env browser */
import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import MarkerIoLoader from './src/components/marker-io-loader';
import 'gatsby-plugin-prismic-previews/dist/styles.css';

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider>
      <MarkerIoLoader>
        {element}
      </MarkerIoLoader>
    </PrismicPreviewProvider>
  );
};
